.map {
    flex: 2 2 465px;
    display: flex;
    max-height: 400px;

    @media (max-width: $max-md) {
        min-height: 40vw;
        flex: 2 2 600px;
    }

    @media (min-width: $max-md) {
        min-height: 20vw;
    }

    .map--world {
        flex: 1 1 60%;
        min-width: 60%;
    }

    circle {
        fill: #87c0ff;
    }

    .rsm-marker {
        animation: glow 5s infinite;
    }

    h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;

        letter-spacing: 1.75px;
        text-transform: uppercase;
        color: #ffffff;
    }
}

@keyframes glow {
    0% {
        filter: drop-shadow(0px 0px 5px rgba(135, 192, 255, 0.9));
    }

    10% {
        filter: drop-shadow(0px 0px 7px rgba(135, 192, 255, 1));
    }

    20% {
        filter: drop-shadow(0px 0px 5px rgba(135, 192, 255, 0.9));
    }

    100% {
        filter: drop-shadow(0px 0px 5px rgba(135, 192, 255, 0.9));
    }
}
