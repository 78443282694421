.block {
    @extend .no-scrollbar;
    overflow-y: auto;
    flex-grow: 1;
    background-color: $blue-dark;
    border-radius: 4px;
    padding: 0;
    flex-basis: 0;

    height: 600px;

    display: flex;
    flex-direction: column;

    &.basic {
        cursor: pointer;
    }
}

.block--title .svg-inline--fa {
    margin-top: -2px;
    margin-right: 10px;
    font-size: 1.5em;
}

.block--title svg {
    margin-bottom: -3px;
    margin-right: 10px;
    font-size: 1.5em;
}

.block--body {
    @extend .scrollable;
    @extend .no-scrollbar;
    flex-grow: 2;
}

.block--title {
    display: flex;
    justify-content: flex-start;
    margin: 18px 20px;

    > h3 {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        margin: 0;

        font-style: normal;
        font-weight: bold;

        letter-spacing: 1.75px;
        text-transform: uppercase;

        color: #ffffff;

        svg {
            height: 18px;
        }
    }
}

.block--advanced {
    @extend .scrollable;
    @extend .no-scrollbar;

    flex-grow: 2;
    margin: 10px 0;

    display: flex;
    flex-direction: column;
}

.block--advanced--top {
    padding: 0 22px;
}

.block--advanced--bottom {
    @extend .scrollable;
    @extend .no-scrollbar;
    margin-top: 10px;
    width: 100%;
    flex-grow: 1;
}

/**** GAS BLOCK *****/

.gas-block {
    flex-grow: 1 !important;
}

.gas-block--advanced--value {
    font-size: 38px;
}

.gas-block--advanced--unit {
    margin-left: 3px;
    font-size: 22px;
    color: rgba(255, 255, 255, 0.4);
}

/**** FEES BLOCK *****/

.fees-switcher {
    position: relative;
    &--control {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.4);
    }
    &--button {
        cursor: pointer;

        &--active {
            color: #ffffff;
            border-bottom: 2px solid $primary;
        }
    }
    &--divider {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.fees-block {
    overflow-y: unset;

    .block--advanced--bottom {
        border-top: 2px solid $blue-darker;
        background: #001f41;
    }

    .block--advanced--top {
        .fees-block--total {
            margin-bottom: 10px;
        }

        .button {
            margin-bottom: 50px;
            width: 170px;
        }
    }

    .tabs--outer {
        display: flex;
        flex-flow: column;
    }

    .block--advanced {
        margin: 0;
    }
}

.fees-block--advanced--value {
    font-size: 48px;
}

.fees-block--advanced--unit {
    margin-left: 3px;
    font-size: 24px;
    opacity: 0.6;
}

.fees-block--advanced--sign {
    font-size: 36px;
    opacity: 0.6;
    margin-right: 3px;
    margin-left: 0;
}

.fees-block--table tbody tr:nth-child(odd),
.fees-block--table tbody tr:nth-child(even) {
    background: none;
    background-color: none;
    border: none;
}

.tabs--outer {
    @extend .scrollable;
    @extend .no-scrollbar;
    width: 100%;
}

.block--advanced--claim {
    // TODO: Look into stylings being overridden
    background: none !important;
    color: white !important;
    border: 1px solid white !important;
    width: initial !important;
    padding-left: 20px !important;
    padding-right: 20px !important;

    .svg-inline--fa {
        margin-right: 2px;
        font-size: 12px;
    }
}

.fees-block--table {
    thead {
        tr {
            border: none;
        }

        tr td:first-child {
            padding-left: 20px;
        }
    }

    tbody {
        tr:hover td {
            background-color: rgba(255, 255, 255, 0.05);
        }

        tr td:first-child {
            padding-left: 20px;
        }

        tr td:last-child {
            padding-right: 40px;
        }

        td {
            color: white;
            font-size: 14px;
            font-weight: 400;
            padding: 10px 0;
            padding-bottom: 14px;
        }
    }

    .percent-bar {
        &.BTC {
            background: #f2a900;
        }

        &.ZEC {
            background: #fabb00;
        }

        &.BCH {
            background: #44c43e;
        }

        &.ETH {
            background: #4a45ad;
        }

        &.DAI {
            background: #006fe8;
        }

        &.FIL {
            background: #00d2d6;
        }

        &.LUNA {
            background: #578de9;
        }

        &.DOGE {
            background: #c2a633;
        }
    }

    .fees-block--table--token {
        align-items: flex-start;

        svg {
            margin-bottom: -6px;
        }
    }

    .tr {
        &,
        td {
            padding-top: 30px;
            color: $green;
            color: white;
            font-size: 18px;
            text-align: left;
        }

        &:hover,
        td:hover {
            background: initial;
        }
    }

    button {
        border: none;
        background: none;
        color: white;
        min-width: auto;
        font-size: 14px;
        font-weight: 100;

        padding-right: 10px;
        padding-left: 10px;
        margin: 0;
        float: right;

        svg.svg-inline--fa {
            padding-right: 0;
            margin-right: 0;
        }
    }

    .fees-block--table--value {
        font-weight: 500;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .fees-block--table--usd {
        font-size: 14px;
        text-align: right;
    }

    .fees-block--table--usd-symbol {
        opacity: 0.6;
    }

    .fees-block--table--icon {
        width: 20px;
        margin-right: 10px;
    }
}

.resources-block {
    margin-top: 0 !important;
    border: 2px solid #001b3a;
}

.epoch--block--charts {
    @extend .resources--block--charts;
    margin-top: 10px;
    margin-bottom: 10px;
}

.epoch-chart {
    svg {
        height: 100px;
    }
}

.epoch-chart--small {
    svg {
        height: 60px;
    }
}

.epoch--block--charts--small {
    justify-content: flex-start !important;
    flex: 1 !important;
    max-width: 600px;
}

.epoch-right {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;

    p {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;

        /* identical to box height */

        color: #f9f9f9;
    }

    .epoch--end-date {
        font-size: 14px;
        opacity: 0.8;
    }
}

.network-block {
    flex-grow: 1 !important;

    .network-block--info {
        @extend .no-scrollbar;

        width: 100%;
        overflow: scroll;
        margin-bottom: 5px;
        padding: 0 22px;
    }

    .darknode-info {
        font-size: 14px;

        tr {
            background: none;
            border: none;
        }

        td + td {
            @extend .monospace;
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            min-width: 0px;
        }

        td {
            padding: 0;
            padding-right: 5px;
        }

        tr td:first-child {
            font-weight: 900;
        }
    }

    .darknode-info--title {
        font-weight: bold;
        margin-bottom: 5px;
    }
}

.version-block {
    @extend .network-block;

    flex-grow: 1 !important;

    .block--advanced--bottom {
        border-top: 2px solid $blue-darker;
        background: #001f41;
        height: 100%;
        padding: 30px 0;
    }

    .version-block--status {
        display: flex;
        padding: 20px;
        align-items: center;

        font-weight: 500;
        font-size: 28px;

        .status-dot {
            margin-right: 10px;
        }
    }
}

.gas-block .block--body {
    padding: 0 22px;
}

.fees-block .block--body {
    display: flex;
}

/* Gas Graph */

.gas-graph {
    color: $red;
}

.gas-graph .block--body {
    display: flex;
    flex-direction: column;
    padding: 0 40px;

    canvas {
        width: 100% !important;
        height: 150px !important;
    }

    .graph-placeholder {
        width: 100% !important;
        height: 150px !important;
    }
}

.gas-graph--times {
    display: flex;
    margin-top: 5px;

    button {
        background: none;
        border-top: none;
        border-left: none;
        border-right: none;
        color: rgba(255, 255, 255, 0.4);
        font-weight: bold;
        font-size: 12px;
        min-width: initial;

        text-align: center;
        flex-grow: 1;
        border-bottom: 5px solid rgba(240, 240, 240, 0.05);
    }

    .selected {
        border-bottom: 5px solid $red;
        cursor: default;
    }
}

.tabs {
    @extend .no-scrollbar;

    border-bottom: 2px solid #001b3a;
    display: flex;
    flex-flow: row;
    width: 100%;
    margin-bottom: 20px;
    padding: 0 22px;

    overflow-y: scroll;
    margin-top: 10px;

    .tab {
        margin-bottom: 0px;
        background: none;
        border: none;
        font-weight: 100;
        cursor: pointer;
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 10px 20px;

        font-weight: bold;
        font-size: 14px;
        line-height: 16px;

        /* identical to box height */
        text-transform: uppercase;

        color: #ffffff66;

        &.selected {
            color: white;
            border-bottom: 2px solid #006fe8;
        }

        &:focus {
            outline: 0;
        }
    }
}

.withdraw-fees {
    height: initial;
}

.withdraw-fees-disabled {
    &:hover:after {
        position: absolute;
        top: 5px;
        left: -312px;
        width: 300px;
        content: attr(title);
        padding: 5px;
        background-color: #19324e;
        border-radius: 4px;
        line-height: 1.4em;
        color: $red;
        font-weight: 600;
        border: 1px solid $red;
    }
}
