$collapsed-width: 80px;
$full-width: 360px;
$mobile-full-width: 100vw;

$max-sidebar: $max-md;
$min-header: $min-lg;

.sidebar .blocky {
    width: 42px;
    border-radius: 50%;
}

.sidebar .status-dot {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 10px;
    width: 0 !important;
    background: $blue-dark !important;
}

.sidebar--row {
    height: 100px;
    border-left: 4px solid rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &:hover {
        border-left: 4px solid transparentize($primary, 1 - 0.5);
        background-color: $blue-dark;
    }
}

.sidebar--top {
    @extend .no-scrollbar;
    overflow-y: scroll;
    height: 100%;
    flex: 1;
    display: flex;
    flex-flow: column;
}

.sidebar--darknodes {
    background: url("../../styles/images/sidebar-empty.svg") repeat-y;

    @media (max-width: $max-sidebar) {
        background: none;
    }

    background-position-x: 19px;
    background-position-y: 27px;
    flex: 1;
}

.sidebar .sidebar--nav li {
    height: 50px;
}

.sidebar--nav {
    margin-bottom: 50px;
    cursor: pointer;
}

.sidebar--nav--icon svg {
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.4;
    font-size: 30px;
    text-align: center;

    font-size: 2em;

    position: relative;
    display: table-cell;
    width: 60px;
    text-align: center;
    vertical-align: middle;
    font-size: 20px;
}

.sidebar--nav--icon--circle {
    background: rgba(255, 255, 255, 0.08);
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidebar--plus {
    background: none;
    margin-top: -5px;
}

.external-link {
    opacity: 0.2;
    margin-left: 5px;
    margin-bottom: -1px;
}

.sidebar.sidebar--mobile--active {
    @media (max-width: $max-sidebar) {
        width: $mobile-full-width;
    }
}

.sidebar--logged-in:hover {
    @media (min-width: $min-header) {
        width: $full-width;
        overflow-x: visible;
    }
}

.sidebar--row.sidebar--active {
    border-left: 4px solid $primary;
}

.sidebar--row.sidebar--faded {
    > * {
        opacity: 0.4;
    }
}

.sidebar--icon {
    width: $collapsed-width - 4px * 2;
    margin-right: 4px;
    display: flex;
    justify-content: center;
    position: relative;
}

.sidebar--icon--empty {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    background: rgba(0, 111, 232, 0.2);
}

.sidebar {
    @extend .no-scrollbar;
    position: fixed;
    background-color: $blue-darker;
    border-right: 2px solid transparentize(#58585b, 1 - 0.2);
    padding: 0;
    top: 0;
    bottom: 0;
    left: 0;
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
    height: 100vh;
    width: $collapsed-width;
    display: flex;
    flex-flow: column;

    @media (max-width: $max-sidebar) {
        width: 0px;
    }

    overflow-x: hidden;
    -webkit-transition: width 0.1s linear;
    transition: width 0.1s linear;
    -webkit-transform: translateZ(0) scale(1, 1);
    z-index: 20;
}

.sidebar::-webkit-scrollbar {
    width: 0px;
    /* remove scrollbar */
    background: transparent;
}

.sidebar--nav {
    margin: 0;
}

.sidebar--row {
    position: relative;
    width: $full-width - 2px;

    @media (max-width: $max-sidebar) {
        width: calc(100vw - 2px);
    }
}

.sidebar--row a {
    position: relative;
    display: table;
    border-collapse: collapse;
    border-spacing: 0;
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;

    -webkit-transform: translateZ(0) scale(1, 1);
    transform: translateZ(0) scale(1, 1);

    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
}

.sidebar .sidebar--text {
    width: $full-width - $collapsed-width;

    @media (max-width: $max-sidebar) {
        width: calc(100vw - 80px);
    }

    font-weight: normal;
    font-size: 15px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    color: #ffffff;

    .sidebar--name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .sidebar--text--details {
        font-size: 12px;
        display: flex;

        > div {
            display: flex;
        }

        > div + div {
            margin-left: 20px;
        }

        svg {
            width: 15px;
            height: 15px;
            opacity: 1;
            margin-right: 5px;
        }
    }

    .sidebar--text--rewards {
        display: flex;
        align-items: center;
    }

    .sidebar--text--gas {
        @extend .sidebar--text--rewards;
    }

    .currency-symbol {
        font-weight: normal;
        mix-blend-mode: normal;
        opacity: 0.4;
        margin-left: 5px;
    }

    .currency-value {
        font-weight: bold;
    }

    .sidebar--text--icon {
        mix-blend-mode: normal;
        opacity: 0.4;
        margin-right: 10px;
    }
}

nav {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

nav ul,
nav li {
    outline: 0;
    margin: 0;
    padding: 0;
}

.sidebar--search {
    background: #002247cc;

    @media (max-width: $max-sidebar) {
        display: none;
    }
}

input[type="text"].sidebar--search--input {
    background: #f5f5f5;
    border-radius: 15.5px;

    font-size: 12px;
    line-height: 14px;
    padding: 0 15px;

    &::placeholder {
        font-weight: 600;
        color: #979797;
    }
}

.search--filter--feedback {
    visibility: hidden;
    color: #979797;
    padding: 0 30px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-overflow: ellipsis;
    white-space: pre;
    overflow-x: hidden;
}

.sidebar:hover {
    @media (min-width: $min-header) {
        .search--filter--feedback {
            visibility: initial;
        }
    }
}

.sidebar--connect {
    background: #002b5c;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    transform: rotate(-90deg);
    position: absolute;
    top: 50vh;
    width: 240px;
    height: 34px;
    left: -23px;
    z-index: 20;

    cursor: pointer;

    font-size: 14px;
    line-height: 16px;
    color: #f9f9f9;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 20px;

    span {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;

        color: #f9f9f9;
    }

    .wallet-icon {
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
    }

    .wallet-icon--inner {
        background: url("../../styles/images/wallet.svg") no-repeat;
        background-size: 12px 14px;
        background-color: $primary;
        margin: 0;
        height: 14px;
        width: 12px;
    }

    @media (max-width: $max-sidebar) {
        display: none;
    }
}

.sidebar--close {
    cursor: pointer;
    height: 48px;
    justify-content: flex-end;
}

.sidebar--nav {
    height: 48px;
}
