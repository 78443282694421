.overlay {
    display: block;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 46px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 40;
}

.popup {
    max-width: 94vw;
    color: white;
    background: #00254e;
    border-radius: 6px;

    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);

    text-align: center;
    padding: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;

    a {
        color: $primary;
    }

    &--size-medium {
        width: 100%;
        max-width: 480px;
    }

    &--padded-medium {
        padding: 34px;
    }

    &--align-left {
        text-align: left;
    }
}

.popup h2,
.popup p {
    margin: 0;
}

.popup h2 + * {
    margin-top: 10px;
}

.popup.open,
.popup.kyc {
    min-width: 300px;
}

.popup.accounts h2 {
    text-align: center;
}

.popup.accounts .account--button {
    padding: 20px;
    width: 500px;
    height: 40px;
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 10px;
}

.popup.accounts .blocky {
    height: 40px;
}

.popup.accounts .account--left {
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    margin-top: -20px;
    margin-left: -20px;
    float: left;
}

.popup.accounts .account--right {
    margin-top: -9px;
    padding-left: 20px;
    font-size: 15px;
    float: left;
}

.popup.atom button {
    width: 100%;
    margin-top: 10px;
}

.popup--buttons {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    button, a {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;

        &:hover:not(:disabled) {
            color: #e9e9e9;
            transform: translateY(-1px);
            box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1),
                0 3px 6px rgba(0, 0, 0, 0.08) !important;
        }

        &.button--alt:hover:not(:disabled) {
            color: #006fe8;
        }

        &:active:not(:disabled) {
            transform: translateY(0px);
        }

        &.button--white {
            border: 1.6px solid white;
            color: white;
            background: transparent;
        }

        &.button--blue {
            background: linear-gradient(
                182.92deg,
                #00a8f5 -172.9%,
                #006fe8 95.79%
            );
            color: white;
        }

        & + button, a {
            margin-left: 20px;
        }
    }
}

/* Open Transfer */

.popup.open .open--tokens {
    display: flex;
    justify-content: center;
}

.popup.open .open--tokens div {
    position: relative;
}

.popup.open .open--tokens p {
    position: absolute;
    left: 50%;
    bottom: -15px;
    transform: translateX(-50%);
    white-space: nowrap;
}

.popup.open .open--arrow {
    display: flex;
    align-items: center;
    height: 50px;
    margin: 0 15px;
}

.popup.open img {
    background-color: white;
    border-radius: 100%;
    width: 50px;
    margin-bottom: 5px;
}

.popup.open .open--details {
    margin: 25px 0 15px;
}

.popup.open .open--details span {
    font-weight: 500;
}

$noWeb3Width: 454px;
$noWeb3Padding: 20px;

.no-web3 {
    width: $noWeb3Width;
    padding: $noWeb3Padding;
}

.no-web3 h2 {
    font-size: 24px;
    font-weight: 500;
    margin: 20px 0;
}

.no-web3 button {
    margin-top: 20px;
    font-size: 18px;
    height: 40px;
}

.popup--content {
    font-size: 14px;
    text-align: left;
    margin: 48px 0;
    &--medium-height {
        height: 310px;
    }
}

.popup--description {
    font-size: 16px;
    margin: 20px 0;
}

.popup--logged-out {
    .blocky {
        width: 20px;
        margin-bottom: -5px;
    }

    .blocky--outer {
        display: inline-block;
        margin-left: 10px;
    }
}

.popup--error {
    @extend .red;

    margin: 5px 0;
    border: 1px solid $red;
    padding: 16px;
    padding-top: 20px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 4px;

    > .popup--error-label {
        margin-top: -27px;
        width: 35px;
        padding: 0 5px;
        font-size: 12px;
        background: #00254e;
        display: block;
        margin-bottom: 10px;
    }
}

.popup--blur {
    pointer-events: none;
    filter: blur(2px);
}

.popup-backdrop--blur {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    backdrop-filter: blur(2px);
    z-index: 15;
}

.popup--container {
    position: absolute;
    top: 0;
    width: 100%;
}
//.popup--blur--fixed {
//    position: fixed;
//    height: 100%;
//    width: 100%;
//    pointer-events: none;
//    filter: blur(2px);
//}

/* Multi Step */

.multi-step {
    padding: 0px;
    width: 450px;
    align-items: flex-start;
    text-align: left;

    > * {
        padding: 20px;

        & + * {
            padding-top: 10px;
        }
    }

    h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 18px;

        color: #ffffff;

        margin-top: 0;
    }

    .popup--error {
        margin-top: 20px;
    }

    a {
        color: #00a8f5;
        text-decoration: none;
        font-size: 15px;
    }
}

.multi-step--list {
    list-style: none;
    padding: 0;
}

.multi-step--list li {
    position: relative;

    display: flex;
    align-items: center;
}

.multi-step--list li + li {
    margin-top: 5px;
}

.multi-step--list span.active {
    font-weight: 500;
}

.multi-step h2 {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: -6px;
    margin-left: 10px;
}

.multi-step--top {
    padding-top: 25px;

    > img {
        margin-bottom: 30px;
        margin-top: 20px;
    }
}

.multi-step--description {
    font-size: 16px;
}

.multi-step--top--warn {
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.popup .multi-step--ignore {
    cursor: pointer;
}

.popup .multi-step--with-ignore {
    margin-bottom: 20px;
}

.connect-web3--browsers {
    display: flex;
    flex-flow: row;
    justify-content: center;

    margin-bottom: 20px;

    svg {
        height: 40px;
        width: auto;
        max-width: 40px;
    }

    a + a {
        margin-left: 10px;
    }
}

button.popup--x {
    border: 0;
    margin: 0;
    width: unset;
    min-width: unset;

    height: 0.66em;
    position: absolute;
    padding: 0.25em;
    top: 1.33em;
    right: 1.33em;
    font-size: inherit;
    font-family: inherit;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        height: 10px;
        width: 10px;

        :hover {
            fill: white;
        }
    }
}
