.label {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    position: relative;
}

.label--icon {
    height: 15px;
    width: 15px;
    cursor: pointer;
}

.label--icon:hover + .label--message {
    display: block;
}

.label--message {
    display: none;
    font-weight: normal;
    line-height: 1.4;
    background-color: white;
    color: $blue-darker;
    border: 1px solid $light-grey;
    border-radius: 4px;
    box-shadow: 0 10px 25px 0 $shadow;
    padding: 5px 10px;
    position: fixed;
    transform: translateX(-50%) translateY(-100%);
    z-index: 1;
    width: 200px;
    text-align: center;
    white-space: normal;
}

.label--message:after,
.label--message:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.label--message:after {
    border-top-color: #ffffff;
    border-width: 7px;
    margin-left: -7px;
}

.label--message:before {
    border-top-color: #cccccc;
    border-width: 8px;
    margin-left: -8px;
}
