// general modifier for showing tooltip outside of chart canvas
.highcharts--with-outside-tooltip {
    max-width: 100%;
    .highcharts-container {
        overflow: visible !important;

        svg:not(:root) {
            overflow: visible;
        }
    }

    div[data-highcharts-chart] {
        overflow: visible !important;
    }
}

.overview--chart--canvas {
    > div > div,
    > div > div > svg {
        max-width: 100%;
    }
}
