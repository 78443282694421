$off-white: #f8fafb;
$dark-grey: #3f3f41;
$light-grey: #cccccc;
$mid-grey: #979797;

$green: #31cc9a;
$green: #3cbc98;
$red: #f45532;
$orange: #db6500;
$light-red: #f16262;

$navy: rgba(0, 28, 58, 1);

$primary: #006fe8;
$blue-dark: #002247;
$blue-darker: #001b3a;
$blue-darker: #001732;

$shadow: rgba(50, 94, 128, 0.2);
$border: rgba(88, 88, 91, 0.1);

$cubic-bezier: cubic-bezier(0.8, 0, 0.2, 1);

$path: "images/";
