.view td > * {
    vertical-align: middle;
}

.view td img {
    width: 20px;
    margin-right: 10px;
}

.view--external {
    text-decoration: none;
}
