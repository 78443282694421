.statistic--row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    .statistic--row {
        margin-bottom: 20px;
    }
}

.stat {
    .hr {
        border-bottom: 1px solid #1a2e46;
        height: 1px;
        width: 100%;
        border-color: #1a2e46;
        margin-bottom: 15px;
        border-width: 1px;

        @media (min-width: $min-lg) {
            margin-bottom: 25px;
        }
    }

    flex-grow: 1;
    margin-bottom: 10px;

    &:not(:last-child) {
        margin-right: 20px;
    }
}

.stat .stat,
.stat.stat--nested {
    .hr {
        border: none;
        margin-bottom: 0;
    }

    .stat--title {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 1.5px;
        height: 28px;
        color: #f9f9f9;
        margin-bottom: 0px;
    }

    .stat--title--outer {
        margin-bottom: 0;
    }
}

.stat--title {
    font-size: 14px;

    line-height: 16px;
    font-weight: bold;

    letter-spacing: 1.75px;
    text-transform: uppercase;

    color: #ffffff;
    display: flex;
    align-items: center;
    padding-bottom: 0;
    margin-bottom: 0;
}

.stat--title--outer {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .stat--title {
        margin-bottom: 0;
    }
}

.stat--title--icon {
    margin-right: 10px;
}

.stat--highlight {
    background: #001327;
    border-radius: 4px;
    // Left
    padding-left: 20px;
    padding-right: 20px;
    // Top
    padding-top: 5px;
    margin-top: -5px;
    // Bottom
    margin-bottom: 0px;
}

.stat.stat--dark {
    @extend .stat--highlight;
    background: darken($color: #001327, $amount: 2);
    margin: 10px;
    margin-top: 0;
}

.stat--children--big {
    font-size: 32px;
    line-height: 37px;
    color: #f9f9f9;

    @media (max-width: $max-md) {
        font-size: 28px;
    }

    .loading {
        height: 38px;
    }

    .stat--children--diff {
        @extend .change-indicator;
        font-style: normal;
        font-weight: 500;
        font-size: 16.2px;
        line-height: 19px;

        &::after {
            font-size: 14px !important;
        }
    }
}

.change-indicator {
    display: inline-block;
    &.positive {
        color: $green;

        &::after {
            content: "\25b2";
        }
    }

    &.negative {
        color: #ff4545;

        &::after {
            content: "\25bc";
        }
    }

    &::after {
        font-size: 24px;
        display: inline-block;
        transform: scaleY(0.8) translateY(1px);
    }
}

.stat--subtitle {
    margin-left: 4px;
    color: #ffffff66;
}
