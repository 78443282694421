$icon-size: 14px;
$tooltip-width: 260px;

.info-label {
    margin-left: 5px;
    position: relative;
    display: flex;
    align-items: center;
    align-self: center;

    &.info-label-bottom {
        .info-label--message:after {
            border-width: 7px;
            margin-left: -1px;
            bottom: 100%;
            border-bottom-color: #ffffff;
        }
    }
}

.info-label--icon {
    background-size: contain;
    background-repeat: no-repeat;
    width: $icon-size;
    height: $icon-size;
    cursor: pointer;
    margin-bottom: 2px; // hoist by 2 px to be visually centered for uppercased labels
}

.info-label--active {
    .info-label--message {
        display: block;
    }
}

.info-label--fading {
    .info-label--message {
        opacity: 0;
        transition: opacity 200ms;
    }
}

.info-label--message {
    font-size: 13px;
    line-height: initial;
    letter-spacing: normal;
    text-align: center;
    background-color: white;
    color: #3f3f48;
    padding: 15px;
    border-radius: 4px;
    position: absolute;
    display: none;
    transition: all 0.2s;
    z-index: 100;
    white-space: normal;

    top: 100%;
    margin-top: $icon-size / 2 + 1;
    width: $tooltip-width;
    margin-left: -$tooltip-width / 2;

    &::after {
        left: 50%;
    }

    &::after {
        bottom: 100%;
        border-color: transparent transparent #333 transparent;
    }

    &::after {
        content: "";
        left: 50%;
        position: absolute;
        border-width: 5px;
        border-style: solid;
    }
}
