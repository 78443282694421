.bn-onboard-modal {
    z-index: 1000;
}

.bn-onboard-modal-content {
    color: white !important;
    background: #00254e !important;
    border-radius: 6px !important;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2) !important;
    font-family: "SuisseIntl", Helvetica, Arial, sans-serif !important;

    .bn-onboard-select-description {
        display: none;
    }

    .bn-onboard-select-info-container {
        position: absolute;
        margin-top: -20px;
    }

    .bn-onboard-icon-button {
        padding: 30px !important;

        &:hover {
            background: $blue-dark !important;
        }
    }

    button:not(.bn-onboard-icon-button) {
        border-radius: 4px;
        border: 2px solid $primary !important;
        color: $primary !important;

        &:hover {
            background: $blue-darker !important;
        }
    }

    .bn-onboard-select-info-container {
        position: initial !important;
        margin-bottom: -10px;
    }

    .bn-onboard-select-wallet-info {
        font-size: 12px !important;
        margin-top: 15px !important;
    }

    .bn-onboard-select-wallet-definition {
        margin-top: 20px !important;
    }

    .bn-onboard-selected-wallet {
        background: $primary !important;

        &:hover {
            background: darken($primary, 5%) !important;
        }
    }

    .bn-onboard-modal-content-header-icon {
        background: $blue-darker !important;
    }

    .bn-onboard-modal-select-wallets {
        & > div {
            margin-bottom: -30px !important;
            justify-content: flex-end !important;

            // & > .bn-onboard-prepare-button {
            //     color: transparent !important;
            //     font-size: 0px !important;
            //     border: none !important;
            //     right: 0;

            //     &:after {
            //         content: "Show disabled wallets";
            //         color: #aaa;
            //         font-size: 12px;
            //         margin: 0 10px;
            //     }
            // }
        }
    }
}
