.logging-out {
    margin-top: 49vh;
}

.enhanced {
    background-color: black;

    & p {
        color: #1ec503;
        text-align: left;
    }
}
