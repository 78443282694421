/* Collateral */

.collateral-status {
    .info-label {
        margin-left: 5px;
        .info-label--icon {
            width: 22px;
            height: 22px;
            margin-bottom: 0;
            margin-left: 0;
        }
    }

    .info-label--message {
        left: 5px;
    }
}

.collateral-stats--top {
    margin-bottom: 80px;
}

/* Collateral Status */

.collateral-status {
    color: transparent;
    background-clip: text;
    display: flex;
    align-items: center;

    span {
        transition: all 600ms;
    }

    svg {
        margin-left: 5px;
    }

    .loading {
        height: 15px;
    }
}

.collateral-status--loading {
    opacity: 0;
}

.collateral-status--over {
    background: linear-gradient(
        270deg,
        #4ed487 0%,
        $green 50.12%,
        #48e0e0 100%
    );
    // Use semi-transparent text so that it shows up in Safari but doesn't
    // affect the gradient in other browsers.
    color: #4ed48744;
    background-clip: text;
}

.collateral-status--under {
    background: linear-gradient(
        270deg,
        #ff4545 0%,
        #e6499a 49.85%,
        #c849e6 100%
    );
    color: #ff454544;
    background-clip: text;
}

.collateral-status-outer {
    margin-top: 10px;

    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 30px;
}

.collateral-pre-status {
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.5;
}

/* Collateral Bar Chart */

.collateral-chart--bar {
    height: 59px;
    border-radius: 6px;

    display: flex;
    align-items: center;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;

    > div {
        display: flex;
        justify-content: flex-end;

        width: 0;
        transition: width 0.6s ease-in-out;
    }
}

.collateral-chart--label {
    color: #00172f;
    font-size: 18px;
    line-height: 21px;

    display: flex;
    align-items: flex-start;
    letter-spacing: 1.92857px;
    padding: 5px;

    &.collateral-chart--label--down {
        align-items: flex-end;
    }

    &.collateral-chart--label--right {
        margin-right: -25px;
    }
}

.collateral-chart--l {
    background: linear-gradient(
        270deg,
        #4ed487 0%,
        $green 50.12%,
        #48e0e0 100%
    );
    height: 100%;
    border-radius: 6px;
    z-index: 2;

    &.collateral-chart--l--under {
        background: linear-gradient(
            270deg,
            #ff4545 0%,
            #e6499a 49.85%,
            #c849e6 100%
        );
    }
}

.collateral-chart--b3 {
    height: 55px;
    background: #1f4873;
    z-index: 1;
}

.collateral-chart--b {
    height: 55px;
    background: #192d42;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.collateral-chart--b-line {
    height: 100%;
    border-right: 2px dashed #192d42;
    left: 0;
    z-index: 4;
    position: absolute;
}

/* Collateral Table */

.collateral-table--row--left {
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    font-size: 12px;
    // line-height: 20px;
    letter-spacing: 1.5px;
    color: #ffffff;
}

.collateral-table--row--right {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    font-weight: 500;
    font-size: 12px;
    // line-height: 20px;
    text-align: right;
    letter-spacing: 1.5px;
    color: #ffffff;
}

.collateral-table--bullet {
    width: 19px;
    height: 19px;
    margin-right: 10px;
    border-radius: 100%;

    > div {
        width: 11px;
        height: 11px;
        margin: 4px;
        border-radius: 100%;
    }
}

.row--l {
    .collateral-table--bullet {
        background: linear-gradient(
            270deg,
            #4ed4871e 0%,
            #3cbc981e 50.12%,
            #48e0e01e 100%
        );
    }

    .collateral-table--bullet--inner {
        background: linear-gradient(
            270deg,
            #4ed487 0%,
            $green 50.12%,
            #48e0e0 100%
        );
    }

    &.row--l--under {
        .collateral-table--bullet {
            background: linear-gradient(
                270deg,
                #ff45454c 0%,
                #e6499a4c 49.85%,
                #c849e64c 100%
            );
        }

        .collateral-table--bullet--inner {
            background: linear-gradient(
                270deg,
                #ff4545 0%,
                #e6499a 49.85%,
                #c849e6 100%
            );
        }
    }
}

.row--b3 {
    .collateral-table--bullet {
        background: #1f48731e;
    }

    .collateral-table--bullet--inner {
        background: #1f4873;
    }
}

.row--b {
    .collateral-table--bullet {
        background: #192d421e;
    }

    .collateral-table--bullet--inner {
        background: #192d42;
    }
}

.collateral-table--row + .collateral-table--row {
    border-top: 1px solid #ffffff19;
}

.selectors {
    @media (min-width: $min-md) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    padding-bottom: 8px;
    &--chain {
    }
    &--period {
        margin-left: 10px;
    }
}

.stat--extra-big > div {
    font-size: 42px;
}

// Period Selector

.period-selector {
    display: flex;
    justify-content: flex-end;
}

.period-option {
    cursor: pointer;
    mix-blend-mode: normal;
    opacity: 0.4;
    border: 1px solid #006fe8;
    box-sizing: border-box;
    border-radius: 4px;
    width: 29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 500;
    font-size: 13px;
    text-align: center;
    padding-top: 3px;

    color: #006fe8;

    & + .period-option {
        margin-left: 5px;
    }

    &:hover {
        opacity: 0.8;
    }
    &.period-option--selected {
        opacity: 1;
        border: 2px solid #006fe8;
    }
}

.stat-with-period {
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    flex-direction: column;

    @media (max-width: $max-md) {
        min-width: calc(100vw - 80px);
        width: 100%;
    }

    @media (min-width: $min-lg) {
        max-width: calc(calc(100% - 20px) / 2);
    }

    & + .stat-with-period {
        margin-left: 20px;

        @media (max-width: $max-md) {
            margin-left: 0;
            margin-top: 100px;
        }
    }

    > .stat--extra-big {
        display: flex;
        flex-direction: column;

        > .stat--children {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
    }
}

// Stat Tabs

.stat-tabs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 50px;
    border-bottom: 1px solid #1a2e46;
}

.stat-tab-option {
    cursor: pointer;
    mix-blend-mode: normal;
    opacity: 0.4;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    font-size: 12px;
    line-height: 14px;

    /* identical to box height */
    letter-spacing: 1.5px;
    text-transform: uppercase;

    color: #ffffff;

    text-align: center;

    border-bottom: 1px solid $light-grey;
    padding: 10px 20px;

    & + .stat-tab-option {
        border-left: 1px solid $light-grey;

        &.stat-tab-option--selected {
            border-left: 1px solid #cccccc66;
        }
    }

    &.stat-tab-option--selected {
        opacity: 1;
        border-bottom: 4px solid #006fe8;
        padding-bottom: 8px;
        margin-bottom: -1px;
    }
}

.stat-amount {
    white-space: nowrap;
}

.stat-amount--value {
    margin-right: 10px;
}
//
//.collateral-padding {
//    height: 43px;
//    width: 100%;
//
//    @media (max-width: $max-lg) {
//        display: none;
//    }
//}

.network-fees {
    display: flex;
    width: 100%;

    > div > div {
        display: flex;
        font-size: 14px;
        align-items: center;
        justify-content: center;
        padding: 5px;
        flex-wrap: nowrap;

        > div {
            display: flex;
            flex-wrap: nowrap;
            margin-right: 5px;
        }

        img,
        svg {
            margin-right: 10px;
        }
    }
}

.network-fees-inner {
    @extend .no-scrollbar;
    overflow-x: scroll;

    display: flex;
    align-items: center;
}

.stat.network-fees-stat {
    overflow: hidden;
    min-width: 350px;

    .stat--title--outer {
        margin-bottom: 10px;
    }
}

.overview--bottom > div.volume--error {
    display: block;
    width: auto;
    margin: 0 auto;
    font-size: 14px;
}

.fee-row {
    margin: 3px 0;
    &--chain{
        display: flex;
        align-items: center;
    }
    &--icon-wrapper{
        display: flex;
        align-items: center;
        width: 30px
    }
    &--icon{
        margin-right: 0!important;
        width: 25px!important;
    }
}
