.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 15px;
    height: 15px;
    margin: 0 auto;
    border-radius: 50%;
    border: 2px solid;
    border-color: black transparent black transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.loading.alt.lds-dual-ring:after {
    border-color: white transparent white transparent;
}

.loading--big.lds-dual-ring:after {
    width: 56px;
    height: 56px;
}

.loading--small.lds-dual-ring:after {
    width: 10px !important;
    height: 10px !important;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
