.not-found {
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;

    text-shadow: 2px 2px 14px black;
    font-size: 200px;
    font-weight: 900;
    color: white;

    @media (max-width: $max-sm) {
        font-size: 150px;
    }

    @media (max-width: $max-xs) {
        font-size: 100px;
    }
}
