$select-input-bg-focus: $off-white;
$select-input-border-radius: 0;
$select-input-box-shadow-focus: none;
$select-input-height: 40px;
$select-padding-horizontal: 15px;
$select-menu-max-height: 300px;
$select-option-focused-bg: darken($off-white, 3%);
$select-option-selected-bg: $off-white;

* {
    box-sizing: border-box;
}

html {
    &.noscroll {
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    min-height: 100%;
    background: #001325
        linear-gradient(189.65deg, #000e1a -20.29%, #002146 142.27%);
    background-attachment: fixed;
}

* {
    font-family: "SuisseIntl", Helvetica, Arial, sans-serif;
}

body {
    font-size: 12px;
    margin: 0;
    color: white;
    margin-bottom: 60px !important;
}

h1 {
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 15px;
}

h2 {
    font-size: 18px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 15px;
}

p {
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 15px;
}

a {
    color: black;
}

label {
    display: block;
}

input[type="number"],
input[type="text"],
input[type="password"] {
    border: 1px solid $light-grey;
    height: 35px;
    padding: 0 8px;

    &:disabled {
        color: $mid-grey;
        background: $light-grey;
    }

    &:focus {
        outline: none;
    }
}

input[type="checkbox"] {
    margin: 0 5px 0 0;
    vertical-align: middle;
}

::-webkit-input-placeholder {
    color: $mid-grey;
}

::-moz-placeholder {
    color: $mid-grey;
}

:-ms-input-placeholder {
    color: $mid-grey;
}

:-moz-placeholder {
    color: $mid-grey;
}

select {
    background-color: white;
    border: 1px solid $light-grey;
    border-radius: 0;
    height: 35px;
    padding: 0 10px;
    appearance: none;
}

select:focus {
    background-color: $off-white;
    outline: none;
}

button {
    color: $dark-grey;
    background-color: white;
    border: 1px solid $light-grey;
    height: 35px;
    min-width: 120px;
    position: relative;
    cursor: pointer;
}

.pointer {
    cursor: pointer;
}

button:focus {
    outline: none;
}

button:disabled {
    color: $mid-grey;
    cursor: not-allowed;
}

button:not(:disabled):hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

button .loading:after {
    height: 12px;
    width: 12px;
    border-width: 1px;
}

table {
    border-collapse: collapse;
    width: 100%;
}

table tbody tr:nth-child(even) {
    background-color: white;
}

table tbody tr:nth-child(odd) {
    background-color: $off-white;
}

table tr {
    border: 1px solid $light-grey;
}

table th,
table td {
    height: 35px;
    padding: 0 10px;
    text-align: left;
    white-space: nowrap;
}

table th {
    font-weight: 500;
}

.left {
    text-align: left;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

$spacing: 80px;

.container {
    width: 100%;
    max-width: 1400px + $spacing * 2;
    margin: 0 auto;
    padding: 0 $spacing + 60px;

    @media (max-width: $max-xl) {
        padding-right: 10px;
        padding-left: $spacing + 10px;
    }

    @media (max-width: $max-md) {
        padding: 0 10px;
    }
}

svg.icon {
    margin-right: 5px;
}

.button.button--dark {
    border: 1px solid #007bff;
    color: #007bff;
    border-radius: 4px;
    background: none;
}

.section {
    padding: 60px 0;
}

.section:first-child {
    padding-top: 30px;
}

.section:last-child {
    padding-bottom: 60px;
}

.monospace {
    font-family: "SuisseIntlMono", "Courier New", Courier, monospace;
    letter-spacing: 0.5px;
}

.nowrap {
    white-space: nowrap;
}

.red {
    color: $red;
}

.green {
    color: $green;
}

.blue {
    color: $primary;
}

.orange {
    color: $orange;
}

span[role="button"],
label {
    user-select: none;
}

.table--wrapper {
    display: block;
    overflow-x: auto;
}

.truncate {
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 250px;

    @media (min-width: $min-sm) {
        max-width: none;
    }
}

.blue-box {
    padding: 30px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 5px;
}

.dark-blue-box {
    @extend .blue-box;

    background-color: $blue-dark;
}

.darker-blue-box {
    @extend .blue-box;

    background-color: $blue-darker;
}

.scrollable {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.scrollable::-webkit-scrollbar {
    /* remove scrollbar space */
    width: 0px;
}

.no-underline {
    text-decoration: none;
}

.checkbox {
    appearance: initial;
    width: 22px;
    height: 22px;
    border: none;
    background: transparent;
    position: relative;
    border: 1px solid white;
    float: left;
}

.checkbox:checked:after {
    content: "✓";
    color: $green;
    font-size: 26px;
    margin-top: 2px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.checkbox--error:checked:after {
    content: "✕";
    font-size: 24px;
    margin-top: 2px;
    color: $red;
}

.no-scrollbar {
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        /* remove scrollbar space */
        display: none;
        width: 0px;
        height: 0px;
    }
}

.app--body {
    min-height: calc(100vh - 5px - 50px - 2vh);
    margin-top: calc(50px + 2vh);

    @media (min-width: $min-lg) {
        min-height: calc(100vh - 60px - 50px - 5vh);

        margin-top: calc(50px + 3vh);
        // margin-bottom: 60px;
    }
}

.xs-only {
    @media (min-width: $min-sm) {
        display: none !important;
    }
}

.no-xs {
    @media (max-width: $max-xs) {
        display: none !important;
    }
}

.medium-only {
    @media (min-width: $min-lg) {
        display: none !important;
    }
}

.no-medium {
    @media (max-width: $max-md) {
        display: none !important;
    }
}

.large-only {
    @media (min-width: $min-xl) {
        display: none !important;
    }
}

.no-large {
    @media (max-width: $max-lg) {
        display: none !important;
    }
}

.xl-or-larger {
    @media (max-width: $max-lg) {
        display: none !important;
    }
}

.no-xl-or-larger {
    @media (min-width: $min-xl) {
        display: none !important;
    }
}

.no-spin-button {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
}

.feedbackButton {
    position: fixed;
    right: 30px;
    bottom: 20px;

    @media (max-width: $max-md) {
        display: none !important;
    }
}

.status-dot {
    background: transparentize($color: rgb(255, 187, 0), $amount: 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-right: 4px;

    > span {
        background: rgb(255, 187, 0);
        display: block;
        border-radius: 100%;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
    }

    &.status-dot--green {
        background: transparentize($color: $green, $amount: 0.5);

        > span {
            background: $green;
        }
    }

    &.status-dot--blue {
        background: transparentize($color: $green, $amount: 0.5);

        > span {
            background: $primary;
        }
    }
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    height: initial;
    width: initial;
    color: $primary;

    :hover {
        margin-top: -1px;
    }

    :active {
        margin-top: 0px;
    }
}

.network-stats {
    > * > .statistic--row {
        margin-bottom: 0;
    }
}

.field-wrapper {
    margin: 24px 0;
}

.field-input {
    border-radius: 4px;
}

.field-label {
    font-size: 14px;
    margin: 6px 0;
}

.field-info {
    font-size: 14px;
    margin: 6px 0;
    &--supplemental {
        color: $mid-grey;
    }
    &--flex {
        display: flex;
        justify-content: space-between;
    }
}

.field-error {
    font-size: 14px;
    margin: 6px 0;
    color: $red;
}

.form-error-wrapper {
    color: $red;
    margin: 24px 0;
}

.form-error-header {
    font-size: 24px;
}

.form-error-text {
    font-size: 16px;
}

.field-input {
    border-radius: 2px;
    &--full-width {
        width: 100%;
    }
}
